import React, { useState } from "react";
import "../../styles/CardModal.style.css";
import { useSelector, useDispatch } from "react-redux";
import { remove_items } from "../../actions";
import { Button, Col, Input, Modal, Row } from "antd";

const CardModal = React.forwardRef((props, cartRef) => {
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState(0);

  document.addEventListener('DOMContentLoaded', function() {
	document.addEventListener('click', function(event) {
		console.log('Você clicou na tela!', event.clientX, event.clientY);
	});
});

  // declare dispatch function
  const dispatch = useDispatch();
  // get cartItems array from STORE
  const cartItems = useSelector((state) => state.cartItems);
  // add the delete item function by filtering clicked item and returning new array
  const deleteItem = (item) => {
    // can't manipulate original array
    let list = cartItems;
    let newList = list.filter((x) => x.id !== item.id);
    // execute remove item action
    dispatch(remove_items(newList));
  };

  // finding the total amount from the items in cart
  let total = 0;
  // map the items in cart and add to total
  cartItems.map((item) => {
    total += Number(item.total_price);
    return total;
  });

  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  return (
    <div className="cart-modal" ref={cartRef}>
      <div className="top">
        <p className="cart-title">Carrinho</p>
        <p className="total-price">
          Preço Total: ${total}
          .00
        </p>
      </div>
      <div className="bottom">
        {cartItems.length === 0 ? (
          <div className="empty-cart">
            <p>Seu carrinho está vazio</p>
          </div>
        ) : (
          <div className="occupied-cart">
            <div className="item-list">
              {cartItems.map((item, index) => {
                return (
                  <div className="item" id={item.id} key={index}>
                    <div className="item-prev"></div>
                    <div className="cart-item-info">
                      <p className="item-name">
                        Arma De Brinquedo Metralhadora Bolinha Gel Elétrica
                      </p>
                      <p className="item-quantity">
                        R$79.99 x {item.added_items}{" "}
                        <span>${item.total_price}.00</span>
                      </p>
                    </div>
                    <button
                      className="del"
                      onClick={() => deleteItem(item)}
                    ></button>
                  </div>
                );
              })}
            </div>
            <button className="checkout" onClick={() => handleOpenModal()}>
              Pagar
            </button>
          </div>
        )}
      </div>
      <Modal
        open={openModal}
        footer={[]}
        title={step == 0 ? "Inserir Dados" : "Pagar"}
      >
        {step == 0 && (
          <center>
            <Input
              placeholder="Insira seu nome completo"
			  onClick={(e) => e.preventDefault()}
              style={{
                marginBottom: 15,
                marginTop: 15,
                borderColor: "#03bde4",
              }}
            ></Input>
            <br />
            <Input
						  onClick={(e) => e.preventDefault()}
              placeholder="Insira seu CEP"
              style={{
                marginBottom: 15,
                marginTop: 15,
                borderColor: "#03bde4",
              }}
            ></Input>
            <br />
            <Button
              size="large"
              style={{
                marginBottom: 15,
                marginTop: 15,
                backgroundColor: "#03bde4",
                borderColor: "#03bde4",
                color: "#fff",
                width: "100%",
              }}
              onClick={() => setStep(1)}
            >
              Pagar com PIX
            </Button>
          </center>
        )}
        {step == 1 && (
          <center>
            <img
			width={200}
			height={200}
              src={
                "https://imgs.search.brave.com/XuJ2v66DJgIGwCNyFsVh6J9h44f37SwyXGggvgRpHrg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5nZXR0eWltYWdl/cy5jb20vaWQvMTM5/ODE1MjIwMy9waG90/by9kYXRhLWxhYmVs/aW5nLXF1aWNrLXJl/c3BvbnNlLWNvZGUu/anBnP3M9NjEyeDYx/MiZ3PTAmaz0yMCZj/PW9DeWhSYU9oYUpH/ZVJXeFJIblBvWm9Z/RVhkYzBaVGpaZk1U/aTRpMEJoN2c9"
              }
            />

            <p>
              00020126330014br.gov.bcb.pix01111335366962052040000530398654040.805802BR5919
            </p>
            <br />
            <Button
              style={{
                marginBottom: 15,
                marginTop: 15,
                backgroundColor: "#03bde4",
                borderColor: "#03bde4",
                color: "#fff",
              }}
            >
              Copiar Código
            </Button>
          </center>
        )}
      </Modal>
    </div>
  );
});

export default CardModal;
